(function() {
    window.saPmCoffee = function(settings) {
        var _this = this;
        _this.lqImagesLoaded = 0;
        _this.mqImagesLoaded = 0;
        _this.hqImagesLoaded = 0;
        _this.init = function(){
            _this.checkAutoplay();
            $('main').addClass('hq-bg');
            $('.watch-video').saClick(_this.toggleVideo);
            $('.show-more').saClick(_this.showMore);

            $(window).on('scroll resize', _this.updateBackground);
            _this.updateBackground();
        };
        _this.toggleVideo = function(){
            $('.pmc-hero').toggleClass('video-open');
            sa.video.pauseAll();
        };
        _this.showMore = function(){
            $(this).parent().addClass('expanded');
        };
        _this.checkAutoplay = function(){
            // https://github.com/video-dev/can-autoplay
            canAutoplay.video({
                timeout: 500,
                muted: true,
            }).then(function(data) {
                if (data.result) {
                    // Can autoplay
                    $('.bg-video').prop('src', settings.bgVideoUrl);
                    setTimeout(function(){
                        $('.bg-video').removeClass('no-autoplay');
                    }, 500);
                } else {
                    console.warn(data.error);
                }
            });
        };
        _this.updateBackground = function(){
            var totalHeight = document.documentElement.scrollHeight;
            var windowHeight = window.innerHeight;
            var adjustedHeight = totalHeight - windowHeight;
            var scrollPosition = $('html').scrollTop();
            var percentHeight = (scrollPosition / adjustedHeight) * 100;
            var heightString = '100% ' + percentHeight + '%';
            $('.art-bg').css('background-position', heightString)
        };
        _this.init();
    };
}());
